
import { ActivityLog } from "@/gql"
import { Component, Vue, Prop } from "vue-property-decorator"
import { VTimelineItem, VIcon, VImg, VChip, VAvatar } from "vuetify/lib"
import ActivityItemContent from "./ActivityItemContent.vue"

@Component({
  components: {
    "v-timeline-item": VTimelineItem,
    "v-icon": VIcon,
    "v-img": VImg,
    "v-chip": VChip,
    "v-avatar": VAvatar,
  },
})
export default class ActivityItem extends Vue {
  @Prop({ default: true }) readonly avatar?: boolean
  @Prop() readonly icon?: string
  @Prop({ default: "grey lighten-2" }) readonly color?: string
  @Prop() readonly activity!: ActivityLog
  @Prop({ default: true }) readonly showSince!: boolean
  @Prop({ default: false }) readonly hideDot!: boolean

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render(h: any) {
    return (
      <v-timeline-item
        class="align-start"
        icon-color={this.color == "grey lighten-2" ? "grey darken-2" : "white"}
        icon={this.icon}
        color={this.color}
        hide-dot={this.hideDot}
        fill-dot
      >
        <ActivityItemContent activity={this.activity} show-since={this.showSince}>
          {this.$slots.default}
        </ActivityItemContent>
      </v-timeline-item>
    )
  }
}
