import { inject, Ref } from "vue"
import { ConsultantOnboardingInput, UploadFileInput } from "@/gql"

export type NewConsultantInput = ConsultantOnboardingInput & {
  avatar?: UploadFileInput | null
}

export const useNewConsultantInputForm = (): {
  newConsultantInput: Ref<NewConsultantInput>
  updateNewConsultantInput: (newConsultantInput: Partial<ConsultantOnboardingInput>) => void
} => {
  const newConsultantInput = inject("newConsultantInput") as Ref<NewConsultantInput>

  if (!newConsultantInput.value) {
    throw new Error("newConsultantInput must be provided")
  }

  const updateNewConsultantInput = (value: Partial<ConsultantOnboardingInput>) => {
    newConsultantInput.value = { ...newConsultantInput.value, ...value }
  }

  return { newConsultantInput, updateNewConsultantInput }
}
