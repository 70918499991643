
import { Component, Vue } from "vue-property-decorator"
import StaffHome from "./StaffHome.vue"
import ConsultantHome from "./consultant/ConsultantHome.vue"
import AppPage from "@/components/layout/AppPage.vue"
import { useAccountContext } from "@/hooks/useAccountContext"
import PeraltaClientHome from "@/views/client/ClientDashboard.vue"
import AheadClientHome from "@/views/aheadBusiness/Home.vue"
import { ProductSlugEnum } from "@/gql"

@Component({
  components: {
    AppPage,
    StaffHome,
    ConsultantHome,
    PeraltaClientHome,
    AheadClientHome,
  },
})
export default class Dashboard extends Vue {
  account = useAccountContext()

  get isConsultantUser() {
    return this.account.isConsultant
  }

  get isClientUser() {
    return this.account.isClient
  }

  get clientProduct() {
    return this.account.clientProduct
  }

  get isPeraltaClient() {
    return this.clientProduct === ProductSlugEnum.Peralta
  }

  get isAheadClient() {
    return this.clientProduct === ProductSlugEnum.AheadBusiness
  }

  get isCoreUser() {
    return this.account.isCoreTeam
  }
}
