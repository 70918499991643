import { render, staticRenderFns } from "./MissionUpdates.vue?vue&type=template&id=67f629f6&scoped=true&"
import script from "./MissionUpdates.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MissionUpdates.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MissionUpdates.vue?vue&type=style&index=0&id=67f629f6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f629f6",
  null
  
)

export default component.exports