var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.consultant)?_c('section',{staticClass:"pa-0 mb-1 mx-0",staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"pb-6 mb-4",staticStyle:{"position":"relative"},attrs:{"flat":_vm.flat,"outlined":_vm.flat}},[(!_vm.hideDetails)?_c('v-img',{attrs:{"height":"200px","src":"/profile-header.png"}},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-app-bar-nav-icon',{attrs:{"color":"white"}}),_c('v-spacer'),(_vm.editable)?_c('div',{staticClass:"tools d-flex"},[_c('v-spacer'),_c('primary-button',{staticStyle:{"background":"#fff"},attrs:{"color":"white","id":"edit-profile-btn"},on:{"click":function($event){_vm.showEditModal = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("la-pencil")]),_vm._v(" Edit Profile ")],1),(false)?_c('v-btn',{staticClass:"ml-4",attrs:{"width":"36","height":"36"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("la-ellipsis-v")])],1):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(!_vm.hideDetails)?_c('div',{staticClass:"profile-img-container"},[_c('user-avatar',{staticClass:"profile-image-ring",attrs:{"size":130,"user":{
          avatar: _vm.consultant.user ? _vm.consultant.user.avatar : null,
          lastName: _vm.consultant.lastName,
          firstName: _vm.consultant.firstName,
        }}})],1):_vm._e(),_c('div',{staticClass:"profile-text d-flex black--text mt-4",class:[_vm.hideDetails ? 'collapsed' : 'expanded']},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"profile-left d-flex flex-grow flex-column"},[_c('div',{staticClass:"profile-text--box d-flex flex-wrap pb-0 align-center border"},[_c('p',{staticClass:"profile-text--heading text-capitalize font-weight-bold"},[_vm._v(" "+_vm._s(_vm.consultant.name)+" ")]),_vm._t("append-applicant-state")],2),_vm._t("subtitle"),_c('div',{staticClass:"profile-text-wrapper pa-0 pb-2 mb-0 mt-0 d-flex flex-column align-start flex-wrap"},[(_vm.consultant?.jobTitles)?_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"profile-text--sub-heading mr-2 pa-0 font-weight"},[_vm._v(" "+_vm._s(_vm.consultant.jobTitles.map((j) => j.name).join(", "))+" "),(_vm.consultant.employmentStatus !== 'unknown')?_c('v-chip',{attrs:{"outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.getEmploymentStatus(_vm.consultant.employmentStatus || "")))]):_vm._e()],1)]):_vm._e(),(_vm.consultant?.jobFunctions)?_c('div',_vm._l((_vm.getJobFunctions(_vm.consultant.jobFunctions || [])),function(jobFunction,idx){return _c('v-chip',{key:idx,staticClass:"mr-2 mt-1 text-capitalize",attrs:{"small":"","color":"#F2F2F2"}},[_vm._v(" "+_vm._s(jobFunction)+" ")])}),1):_vm._e(),_vm._t("append-applicant-score")],2),_vm._t("append-training-tags")],2),(_vm.showSideInfo)?_c('div',{staticClass:"profile-right pl-0 pt-2 pr-2 pb-0 mb-0"},[_c('v-row',{attrs:{"justify":"end"}},[_c('div',{staticClass:"d-flex flex-column font-weight-regular justify-end align-end"},[_c('div',{staticClass:"font-weight-regular mr-3"},[_vm._v(" "+_vm._s(_vm.consultant.gender || "N/A")+" "),_c('v-icon',{class:[
                    'red--text text-lg las ml-2',
                    _vm.consultant.gender === 'Male' ? 'la-male' : 'la-female',
                  ],attrs:{"size":"22"}})],1),(_vm.consultant.country)?_c('div',{staticClass:"font-weight-regular mr-3 my-2"},[_vm._v(" "+_vm._s(_vm.consultant.country.name || "N/A")+" "),_c('v-icon',{staticClass:"red--text text-lg las la-map-marker-alt ml-2",attrs:{"size":"22"}})],1):_vm._e(),(_vm.consultant.phone)?_c('div',{staticClass:"font-weight-regular mr-3 mb-2"},[_vm._v(" "+_vm._s(_vm.consultant.phone || "-")+" "),_c('v-icon',{staticClass:"red--text text-lg las la-phone ml-2",attrs:{"size":"22"}})],1):_vm._e(),_c('div',{staticClass:"font-weight-regular mr-3 mb-2"},[_vm._v(" "+_vm._s(_vm.consultant.email || "-")+" "),_c('v-icon',{staticClass:"red--text text-lg las la-envelope ml-2",attrs:{"size":"22"}})],1),(_vm.consultant.linkedinUrl)?_c('div',{staticClass:"font-weight-regular mr-3 mb-2"},[_c('text-button',{attrs:{"href":_vm.consultant.linkedinUrl,"target":"_blank"}},[_vm._v(" View Linkedin Profile ")]),_c('v-icon',{staticClass:"red--text text-lg lab la-linkedin ml-2",attrs:{"size":"22"}})],1):_vm._e()])])],1):_vm._e()])]),_vm._t("append-expanded-info")],2),(_vm.editable)?_c('Modal',{attrs:{"size":"large"},on:{"close":_vm.onEditModalClose},model:{value:(_vm.showEditModal),callback:function ($$v) {_vm.showEditModal=$$v},expression:"showEditModal"}},[_c('EditProfile',{attrs:{"consultant":_vm.consultant},on:{"save":_vm.onSave}})],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }