import { computed, ComputedRef } from "vue"
import { useAuth } from "@/store/auth/useAuth"
import { ConsultantProfile } from "@/gql"

export type UseConsultantOnboardingChecklistValue = {
  isUserVerified: ComputedRef<boolean | undefined>
  hasReceivingAccount: ComputedRef<boolean>
  hasOnboardingItems: ComputedRef<boolean | undefined>
}

export const useConsultantOnboardingChecklist = (
  consultant: ConsultantProfile
): UseConsultantOnboardingChecklistValue => {
  const { user } = useAuth()

  const hasReceivingAccount = computed(() => {
    return (
      !!consultant.consultantPayoneerAccounts.length || !!consultant.consultantBankAccounts.length
    )
  })

  const isUserVerified = computed(() => user?.verified)

  const hasOnboardingItems = computed(() => !(isUserVerified.value && hasReceivingAccount.value))

  return { isUserVerified, hasReceivingAccount, hasOnboardingItems }
}
