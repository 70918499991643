
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator"
type IPreset = "default" | "year"

@Component
export default class DatePicker extends Vue {
  @Prop({ required: true })
  readonly value!: string

  @Prop({ default: "la-calendar-minus" })
  readonly prependIcon!: string

  @Prop({ default: "" })
  readonly appendIcon!: string

  @Prop({ default: "default" })
  readonly preset!: IPreset

  @Prop({ default: true })
  readonly showTextField!: boolean

  date = ""
  showMenu = false

  mounted() {
    this.onValueChanged()
  }

  get attrs() {
    if (Object.prototype.hasOwnProperty.call(this.$attrs, "outlined")) {
      const { label, ...attrs } = this.$attrs
      return attrs
    } else return this.$attrs
  }

  get generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 200
    var years = []

    for (var i = max; i >= min; i--) {
      years.push(i.toString())
    }
    return years
  }

  @Watch("value")
  onValueChanged() {
    this.date = this.value
  }

  @Emit("change")
  @Emit("input")
  onInput(date: string) {
    this.showMenu = false
    return date
  }
}
