import { Component, Vue } from "vue-property-decorator"
import moment from "moment"
import { DocumentNode, GraphQLError } from "graphql"
import { MutationUpdaterFn, RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import { FetchResult } from "apollo-link"
import EventBus from "@/components/EventBus"
import { ErrorV2 } from "@/gql"
import { OperationVariables } from "apollo-client"

type MutationOptions<
  T extends Record<string, any> = Record<string, any>,
  Q extends OperationVariables = OperationVariables
> = {
  mutation: DocumentNode
  variables?: Q
  refetchQueries?: RefetchQueryDescription
  done?: () => any
  error?: (response: ErrorV2) => any
  success?: (data: T) => void
  update?: MutationUpdaterFn<T>
  handlePayloadErrors?: boolean
}

export interface Utils {
  log(...args: any[]): void
  logWarning(...args: any[]): void
  logError(...args: any[]): void
  hasHistory(): boolean
  truncate(str: string, length?: number): string
  formatDate(date: string | Date, format?: string): string
  debounceCall(fn: () => any, ms?: number): void
  routeBack(route?: string): void

  mutate<
    T extends Record<string, any> = Record<string, any>,
    Q extends OperationVariables = OperationVariables
  >(
    options: MutationOptions<T, Q>
  ): Promise<FetchResult<T>>

  moneyFormat(value: string | number): string

  snakeCaseTextFormat(text: string): string

  assignObjectVals(
    from: { [key: string]: any },
    to: { [key: string]: any },
    mapping?: Record<string, string>
  ): void
  generateRand(length: number): string
  refetchMission(): void
  updateCache<QT>(
    cache: Parameters<MutationUpdaterFn>[0],
    query: DocumentNode,
    fn: (data: QT | null) => any,
    queryVars?: Record<string, any>
  ): void

  zip(rows: any[][]): any[][]
  sanitizeUnderscores(str: string): string
}

@Component
export default class UtilsMixin extends Vue implements Utils {
  private debounceTimeout: ReturnType<typeof setTimeout> | null = null

  log(...args: any[]): void {
    console.log(args)
  }

  logWarning(...args: any[]): void {
    console.log(args)
  }

  logError(...args: any[]): void {
    console.log(args)
  }

  hasHistory(): boolean {
    return window.history && window.history.length > 2
  }

  truncate(str: string, length = 50): string {
    return str?.length > length ? str.substring(0, length) + "..." : str
  }

  formatDate(date: string, format = "Do MMMM YYYY"): string {
    return moment(date).format(format)
  }

  debounceCall(fn: () => any, ms = 200): void {
    if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
    // Set new timeout
    this.debounceTimeout = setTimeout(() => {
      fn.call(this)
    }, ms)
  }

  async mutate<
    T extends Record<string, any> = Record<string, any>,
    Q extends OperationVariables = OperationVariables
  >(options: MutationOptions<T, Q>): Promise<FetchResult<T>> {
    const { handlePayloadErrors = true, ...rest } = options

    // returns Promise of T
    try {
      const result = await this.$apollo.mutate<T>({
        ...rest,
      })

      if (result && result.data) {
        // Handle mutation errors
        const mutationKey = Object.keys(result.data)[0],
          errorResponse = (result.data[mutationKey as keyof T] as T[keyof T] & { error: ErrorV2 })
            .error

        if (errorResponse) {
          if (options.error) options.error.call(result, errorResponse)
          else this.addMutationError(errorResponse)
        } else {
          if (options.success) options.success.call(result, result.data)
        }

        return result
      }
    } catch (e) {
      handlePayloadErrors && this.addGraphQLError(e as Error)

      return {
        errors: e as GraphQLError[],
      }
    } finally {
      options.done?.call(this)
    }

    return {}
  }

  moneyFormat(value: string | number): string {
    return parseFloat(value as string)
      .toFixed(2)
      .toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  snakeCaseTextFormat(value: string): string {
    return value.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
      c ? c.toUpperCase() : " " + d.toUpperCase()
    )
  }

  assignObjectVals(
    from: { [key: string]: any },
    to: { [key: string]: any },
    mapping?: Record<string, string>
  ): void {
    for (const field in to) {
      if (Object.prototype.hasOwnProperty.call(to, field)) {
        const current = from[field]
        if (current) to[field] = current
      }
    }

    // if there is a field mapping
    if (mapping) {
      for (const key in mapping) {
        if (Object.prototype.hasOwnProperty.call(mapping, key)) {
          const current = from[key]
          if (current) to[mapping[key]] = current // mapping[key] returns field name in to
        }
      }
    }
  }

  generateRand(length: number): string {
    let result = ""
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  refetchMission(): void {
    EventBus.$emit("refetch-missionlifecycle-query")
  }

  updateCache<QT>(
    cache: Parameters<MutationUpdaterFn>[0],
    query: DocumentNode,
    fn: (data: QT | null) => any,
    queryVars?: Record<string, any>
  ): void {
    try {
      // fetch data from cache
      const cacheData = cache.readQuery<QT>({
        query,
        variables: queryVars,
      })

      // write query to cache with return value of fn
      cache.writeQuery({
        query,
        variables: queryVars,
        data: fn.call(this, JSON.parse(JSON.stringify(cacheData))),
      })
    } catch (error) {
      console.error(error)
    }
  }

  routeBack(route?: string): void {
    this.hasHistory()
      ? this.$router.back()
      : this.$router.push({
          name: route || this.$routes.Home, // Keep default as Home when no route is specified.
        })
  }

  zip(rows: any[][]): any[][] {
    return rows.length ? rows[0].map((_, c) => rows.map((row) => row[c])) : []
  }

  sanitizeUnderscores(str: string): string {
    return str.replace(/_/g, " ")
  }
}
