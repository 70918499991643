import { ConsultantContractState } from "@/gql"

export const useConsultantContractState = (state: ConsultantContractState): { state: string } => {
  const stateMap: Record<ConsultantContractState, string> = {
    approved: "pending acceptance",
    accepted: "accepted",
    declined: "declined",
    terminating: "terminating",
    terminated: "terminated",
  }

  return { state: stateMap[state] }
}
