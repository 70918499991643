
import { Component, Prop, Vue, Emit } from "vue-property-decorator"

@Component
export default class Modal extends Vue {
  @Prop()
  value!: boolean

  @Prop()
  title!: string

  @Prop()
  subtitle?: string

  @Prop()
  size?: "small" | "medium" | "large" | "xlarge"

  @Prop()
  showClose!: boolean
  @Prop({ default: false }) readonly persistent?: boolean

  @Emit("close")
  onClose() {
    return false
  }

  @Emit("input")
  onInput(value: any) {
    if (!value) this.onClose()

    return value
  }

  get modalWidth() {
    const map = {
      xlarge: 1200,
      small: 400,
      medium: 500,
      large: 800,
    }

    return map[this.size || "medium"]
  }
}
