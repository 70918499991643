
import { ActivityInitiator, ActivityLog } from "@/gql"
import { Component, Vue, Prop } from "vue-property-decorator"
import { VTimelineItem, VIcon, VImg, VChip, VAvatar } from "vuetify/lib"
import moment from "moment"

@Component({
  components: {
    "v-timeline-item": VTimelineItem,
    "v-icon": VIcon,
    "v-img": VImg,
    "v-chip": VChip,
    "v-avatar": VAvatar,
  },
})
export default class ActivityItemContent extends Vue {
  @Prop({ default: true }) readonly avatar?: boolean
  @Prop() readonly activity!: ActivityLog
  @Prop({ default: true }) readonly showSince!: boolean

  get composition() {
    return this.activity.actionComposition
  }

  get initiator(): ActivityInitiator {
    return this.activity.initiator
  }

  get since(): string {
    // get duration
    let created: number = moment().diff(moment(this.activity.createdAt), "minutes"),
      unit = ""

    if (created > 0) {
      unit = "minute"

      if (created > 59) {
        // An hour or more
        created = Math.round(created / 60)
        unit = "hour"

        // A day or more
        if (created > 23) {
          unit = "day"
          created = Math.round(created / 24)

          // A month or more
          if (created > 27) {
            unit = "month"
            created = Math.round(created / 28)

            // A year or more
            if (created > 12) {
              unit = "year"
              created = Math.round(created / 12)
            }
          }
        }
      }

      unit = created > 1 ? `${unit}s` : unit
    }

    return `${created || "just"} ${unit || "now"} ${created ? "ago" : ""}`
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render(h: any) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const reactStringReplace = require("react-string-replace")

    let parsedComposition = reactStringReplace(this.composition, "[U]", () => (
      <span>
        {this.activity.initiatorType == "User" && this.avatar && (
          <v-avatar size="24" class="mr-1">
            {this.initiator.avatar ? (
              <v-img src={this.initiator.avatar.url} />
            ) : (
              <v-icon>la-user-circle</v-icon>
            )}
          </v-avatar>
        )}
        <b class="initiator-description">{this.activity.initiator.name}</b>
        {this.activity.initiatorType == "ModelWatcher" && (
          <v-chip small outlined class="px-2 ml-1 mr-1">
            automation
          </v-chip>
        )}
      </span>
    ))

    let blockActionables: any[] = []

    try {
      // parse actionables to object
      const actionables = this.activity.actionables && JSON.parse(this.activity.actionables)

      if (actionables) {
        // for each actionable replace key in parsed composition with node
        ;(actionables as Record<string, any>[])
          .filter((a) => a.display !== "block")
          .forEach((a) => {
            parsedComposition = reactStringReplace(parsedComposition, `[${a.key}]`, () =>
              a.name_attribute ? (
                <b class={"actionable " + (a.format || "")}>
                  {a.name_attribute == "date" ? this.formatDate(a.date) : a[a.name_attribute]}
                </b>
              ) : (
                <b class={"actionable " + (a.format || "")}>{a.name}</b>
              )
            )
          })

        // block actionables
        blockActionables = (actionables as Record<string, any>[])
          .filter((a) => a.display == "block")
          .map((a) => (
            <div>
              <b>{a.name}</b>
            </div>
          ))
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.error("Error parsing json", error)
      }
    }

    return (
      <div class="activity-item-wrap">
        <div class="activity-item">
          <span>
            {parsedComposition}{" "}
            <span
              class="grey--text text--darken-1 time-since"
              title={this.formatDate(this.activity.createdAt)}
            >
              {this.showSince && this.since}
            </span>
          </span>
          {this.$slots.default}
        </div>
        {blockActionables.length > 0 && (
          <div class="block-actionables mt-3">{blockActionables}</div>
        )}
      </div>
    )
  }
}
